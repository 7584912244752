.h1{
    font-weight: bold;
    color:#741E7E;
    margin-top:80px;
}

h2{
    font-size:40px;
}

.heroSection{
    padding-top:120px !important;
    /* background: #fcf3ff; */
}

.imageSection img{
    box-shadow:0 10px 30px rgba(0,0,0,.15);
    border-radius:50px;    
}

.meetingRoom{
    z-index: 1;
    top:-50px;
    position:relative;
}

.officeSpace{
    float: right;
}

.hide-overflow{
    overflow: hidden;
}

.service-hero .main-head{
    font-weight: 900;
}

@media screen and (max-width:767px){
    .h1{
        margin-top:0;
    }
    .officeSpace{
        margin-top:30px;
        border-radius: 20px !important;
        box-shadow: none !important;
    }    
    .meetingRoom{
        display: none;
    }
}