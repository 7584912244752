.how-we-do-section{     
    overflow: hidden;  
}

.how-we-do-section .sub-head{
    margin-top:0;
}

@media screen and (max-width:767px){ 
    .process-description{
        margin-top: 360px;
    }
}