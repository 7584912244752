.contactProcess{    
    background:#fff url('./images/process.png') no-repeat right top;
    padding:140px 0 0;
    min-height: 602px !important;
}
.contactProcess h2{
    font-size:40px;
    color:#741E7E;
    font-weight: bold;
    margin-bottom:20px;
}

.contactProcess h2 span{
    color:#B2189D;
}

.contactProcess h2 span:last-child{
    color:#D147B6;
}

.contact-form{
    padding:40px 20px 80px 20px;
}

@media screen and (max-width:767px){
    .contactProcess{
        padding-top:60px;
        background-size:100%;
        background-position: bottom center;
        padding-bottom:370px;
    }
}