.service-hero{
    padding:130px 0 !important;
    background: url("./images/hero-image.jpg") no-repeat;
    background-size: cover;
}

.service-hero h1, h4{
    margin:0;
}

.service-hero h4{
    margin-top: 50px;
}

.ceo-image{
    position: relative;
    top:-200px;
    border-radius: 100%;
    max-width: 100%;
}

.main-section-team{
    padding:60px 0;
    max-height:auto;
}

.main-section-textquote{
    padding-bottom:60px;
}

.team-two-section{
    background:#fbe2ff;
}

.team-image{
    border-radius: 200px 200px 200px 0;
}

.team-two-image{
    border-radius: 200px 200px 0 200px;
} 

.ceo-content{
    padding:40px;
}

.ceo-content h2{
    font-weight: bold;
    margin-bottom:20px;
    font-size: 35px;
}

.services-section{
    padding:80px 0;
    background:#fefefe !important; 
}

.services-section h2{
    font-weight: bold;
}

.services-section .service-feature .feature-title{
    font-size:28px;
    display:block;
    font-weight: bold;
    line-height: 30px;
}

.services-section .service-feature .feature-content{
    display: block;
    margin:15px 0;
    font-size:17px;
    line-height: 19px;
}
 
.service-feature :hover{
    background:#fff9ff;
}
 

img.tileImage{ 
    width:100px;
    filter: hue-rotate(90deg) saturate(150%) brightness(100%) contrast(120%);
}

.service-hero .main-head{
    font-weight: 900;
}

.services-section .card .card-body .card-title{
    font-weight: bold;
    font-size:31px;
    margin-top:10px;
    clear:both;
    margin-top:180px;
    position: relative;
}

.services-section .card .card-body p{
    margin:0;
}

.services-section .card .card-body {
    padding:0;
}

.services-section .card {
    padding:15px 40px 40px 40px;
}

.services-section .card:hover{
    background:#ffc;
}

.services-section .card-footer{
    background: none;
    border:none;
    padding:20px 0 0 0;
    text-align: right;
}

.service-hero .service-links{
    background: rgba(255,255,255,.7);
    padding: 30px;
    border-radius: 25px;
}

.service-lnk{
    text-decoration: none;
    color:#741E7E;
    display: block;
    margin-top:0;    
    padding:40px 40px 50px 40px;
    box-shadow: 5px 5px 15px rgba(0,0,0,.01);
    border-right:1px solid #f9f9f9;
    border-bottom:1px solid #f9f9f9;
    position: relative; 
    background-color: #fff;
}

.feature-chevright{
    position:absolute;
    transition: right .2s ease-out;
    right:35px;
    bottom:25px;
}

.service-lnk:hover .feature-chevright{
    right:25px;
}

.home-page-form label{
    display:none;
}

.home-page-form h2{
    font-size:30px;
    line-height: 35px;
}

.home-page-form .text-danger{
    font-size:13px;
}

.home-page-form .card{
    padding:0;
    background-color: #ffcc55;
}
.home-page-form .card-body{
    padding:15px 50px;
}
.home-page-form .card-title p{
    display:none;
}

.home-page-form textarea{
    height: 100px;
}

.section-tiles{
    padding:0 15px;
}

.service-icon{
    background:transparent;
    border-radius:20px;
    /* box-shadow: #741E7E33 10px 10px 50px; */
    margin-top:50px;
    margin-bottom:50px;
}

.service-icon img {
    height: 120px;
    margin: 30px 0;
}

.service-icon img.arrow-icon{
    height:40px;
    margin:0 20px;
}

.section-tiles .title{
    margin-top:15px;
    margin-bottom:10px;
}

.section-img{
    width:70px;
}

.btn-primary.outlined-btn{
    border-radius:5px;
    background: none;
    color:#762e7f !important;
    border: 1px solid #762e7f;
    padding:10px 25px;
    font-size:17px;
    font-weight: 500;
}
 
@media screen and (max-width:767px){
    .ceo-image{
        top:-140px;
    }
    .services-section .card{
        padding:15px 25px;
    }
    .services-section .card .card-body .card-title{
        font-size:30px;
    }
}