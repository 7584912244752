.projectProcess{    
    background:#fff url('./images/process.png') no-repeat right top;
    padding:90px 0 0;
    min-height: 602px !important;
}
.projectProcess h2{
    font-size:40px;
    color:#741E7E;
    font-weight: bold;
}

.projectProcess h2 span{
    color:#B2189D;
}

.projectProcess h2 span:last-child{
    color:#D147B6;
}

@media screen and (max-width:767px){
    .projectProcess{          
        background-size: auto 400px;
        background-position: top right;        
        padding-top:430px !important;
    } 

    .projectProcess h2{
        font-size:40px;
    }
}