.tile-container{
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;  
}
.process-tile {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top:70px;
    padding:20px 20px 0 0;
}

.process-tile p {
    padding: 15px;
    font-size: 15px;
}

.process-tile .card .card-title {
    color: #741E7E;
    font-weight: bold;
    font-size:23px;
}

.process-tile .card .card-title span{
    font-size:44px;
    vertical-align: middle;
    color:#D3D4D7;
}

.process-tile .card {    
    width: calc(50% - 5px);
    padding:5px;
}

.process-tile .card:nth-child(odd) {
    top:-50px;
    z-index: 1;
}

.process-tile .card:hover{
    transform: scale(1.02);
    z-index: 2;    
    cursor:default;
}
@media screen and (max-width:767px){
    .tile-container{
        position: relative;
    }
    .process-tile {
        margin-top:0 !important;
    }
    .process-tile .card{
        position: absolute !important;
        top: 0 !important;
        left: 0;
        z-index: 1;
        transition: all .5s ease-in-out;
    }

    .process-tile .card:nth-child(2){
        top:80px !important;
        z-index: 2;
    }
    .process-tile .card:nth-child(3){
        top:160px !important;
        z-index: 3;
    }
    .process-tile .card:nth-child(4){
        top:240px !important;
        z-index: 4;
    }
    .process-tile .card:active{
        z-index:5;
    }
}

