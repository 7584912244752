
.collabs{
    background:#222A38;
    padding:30px 0;
}

.collabs a{
    display: inline-block;
    text-align: center;
}

.collabs img {
    max-height: 100px;
    margin:0 20px;    
}

.collabs span.sec-title {
    color: #ccc;
}

@media screen and (max-width:600px){
    .collabs img {
        max-width: 40px;
    }
}