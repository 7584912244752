 .footerContrainer{
    color:#fff;
    font-size: 15px;
    border-top:10px #741E7E solid;
    background:#222A38 url(./images/right-swoosh-bg.svg) no-repeat 105% 50px;
}
.footerContrainer .left-background{
    background:url(./images/left-swoosh-bg.svg) no-repeat -7em bottom;
}

.footer-logo{
    margin-bottom:20px;
}
.footerContrainer h5{
    font-size:17px;
    font-weight: 800;
}

.right-section{
    padding:60px 0 60px 5em;
    border-left: 1px solid #4D586A;    
}

.left-section{
    padding:60px 3em 60px 0;
}

.footerContrainer a, 
.footerContrainer a:visited, 
.footerContrainer a:focus{
    color:#fff;
    text-decoration: none;
}

.footerContrainer .nav-link{
    padding:7px 0;
}

.footerContrainer a:hover, .footer-bottom a:hover{
    color:#9b4187
}

.footer-bottom{
    background:#222A38;
    color:#AAB1BE;
    border-top:1px solid #4D586A;
    padding:20px 0;
    font-size: .85rem;
}

.footer-bottom a, 
.footer-bottom a:visited, 
.footer-bottom a:focus{
    color:#AAB1BE;
    text-decoration: none;
    font-size: .85rem;
}

.divider{
    display: inline-block;
    margin:15px 0;
    border-radius: 20px;
    height:8px;
    width:95px;
    background: #741e7e;
    background: linear-gradient(90deg, #741e7e 0%, #e512b5 100%);
}

.contact-links, .contact-links li{
    margin:0;
    padding:0;
    list-style: none;
}
.contact-links li a{
    padding:7px 0;
    display:inline-block;
}


@media screen and (max-width:767px){
    .right-section {
        padding: 0;
        border:none;
    }
    .footerContrainer h5{
        margin-top:40px
    } 
    .collabs{
        text-align: center;
    }
}