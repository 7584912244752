.services-home-section{    
    overflow: hidden;
    padding:80px 0 100px 0;
} 

.services-circles{
    max-width:100%;
    height: auto;
}

svg g#plan-market circle, svg g#plan-market text{
    opacity: 0;
}

svg.show g#plan-market circle:nth-child(4){
    animation: fadeIn .5s ease-in-out forwards;
}

svg.show g#plan-market circle:nth-child(3){
    animation: fadeIn .5s ease-in-out .3s forwards;
}

svg.show g#plan-market circle:nth-child(2){
    animation: fadeIn .5s ease-in-out .6s forwards;
}

svg.show g#plan-market circle:nth-child(1){
    animation: fadeIn .5s ease-in-out .9s forwards;
}

svg.show g#plan-market circle:nth-child(5){
    animation: fadeIn .5s ease-in-out 1.1s forwards;
}

svg.show g#plan-market circle:nth-child(6){
    animation: fadeIn .5s ease-in-out 1.2s forwards;
}

svg.show g#plan-market circle:nth-child(7){
    animation: fadeIn .5s ease-in-out 1.3s forwards;
}

svg.show g#plan-market circle:nth-child(8){
    animation: fadeIn .5s ease-in-out 1.4s forwards;
}

svg.show g#plan-market text{
    animation: fadeIn .5s ease-in-out 1.5s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0; 
    }
    to {
        opacity: 1; 
    }
}

  
/* .services-home-section::before {
    content: ' ';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background: radial-gradient(ellipse at center, rgba(212, 89, 250, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
} */

/* 
.services-section::after {
    content: ' ';
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background: radial-gradient(ellipse at center, rgba(212, 89, 250, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
} */

@media screen and (max-width:991px) {
    .main-head {
        font-size: 27px;
        line-height: 30px;
    }
    .sub-head{
        margin-top:0 !important;
    } 
}

@media screen and (max-width:767px) {
    .services-home-section{
        text-align: center;
    }    
}