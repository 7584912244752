.overlay{
    background:#000000BB;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 99999;
    padding:60px 20px;
    overflow: hidden;
    overflow-y:auto;
    display: none;
    transform: all .5s ease-in-out;
} 

.get-started-banner{
    min-height: 30vh;
    background:#f0f0f0;
}

.get-started-banner canvas{
    max-width:100%;
}

.get-started-form{
    padding:60px 20px; 
}

.get-started-form .container{
    position:relative;    
}

.get-started-form .ql-editor{
    min-height:200px;
}

.get-started-form .form-group{
    margin-bottom:15px;
}

.imageSectionTile .cont{
    width:400px;
    height:400px;
    top:-200px;
    position: relative;
}

.imageSectionTile .imgCont{
    width:300px;
    height:300px;
    right:20px;
    bottom:20px;
}

.CircleImage-col-content{
    position: relative;
    top:-170px;
    padding-right:50px;
}

.get-started-header{
    margin:0 0 30px 0;
    font-weight: 600;
}

.get-started-form label{
    font-weight: 500;
    margin-bottom: 0;
    color:#999;    
    font-size:15px;
    margin-top:10px;
}

.get-started-form form .text-danger{
    font-size:13px;
}

.quill > .ql-container > .ql-editor{
    font-size: 1rem;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
    color:#ddd;
    font-style: normal;    
}

.get-started-process .btn-primary{
    display:none;
}
.get-started-process .projectProcess{
    /* padding-top:80px;
    padding-bottom:60px; */
}

@media screen and (max-width:1439px){
    .get-started-process .projectProcess h2{
        font-size:41px;
    }
}