.blink {
    animation: blink-animation .5s infinite;
    font-weight: 300;
}

@keyframes blink-animation {

    0%,
    49% {
        opacity: 1;
    }

    50%,
    100% {
        opacity: 0;
    }
}