.industries-block {
    padding: 100px 0 60px 0;
    background:#fef7ff;
    overflow: hidden;
}

/* .industries-block::before {
    content: ' ';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background: radial-gradient(ellipse at center, rgba(212, 89, 250, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
}
 */

.industry-tile {
    transition: transform 0.2s ease; 
    background:#fef7ff;
    padding:30px 0;
    margin-bottom:30px;
    border-radius:20px;
    cursor: pointer;
    margin:0 auto;
    position: relative;
}
 

.industry-tile-title{
    font-weight: bold;
    margin-bottom:10px;
    color: #93478f;
    text-align: center;
}

.industry-tile-image{
    max-width:100%;
    display:inline-block !important;
    position: relative;
    animation: hoverEffect 3s ease-in-out infinite;
}

@keyframes hoverEffect {
    0%, 100% {
        /* transform: translate3d(0, 0, 0) scale(1); */
        top:0;
    }
    50% {
        /* transform: translate3d(0, -10px, 0) scale(1); */
        top:-10px;
    }
}

.industry-tile:hover .industry-tile-image{
    animation-play-state: paused;
    top:-10px;
    transform: scale(1.02); 
}

.industry-tile:active .industry-tile-image{
    transform: scale(1.1); 
}

.industry-content-tile{    
    position: absolute;
    width:100%;
    bottom:0;
    opacity: 0;
    background:#fff;    
    border-radius: 20px 20px 0 0;    
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}


.industry-tile.active .industry-content-tile{
    opacity: 1;
    animation: circleToRectangle 1s forwards;
}

.industry-content-tile p {    
    margin: 0;    
}

.industry-tile.active .industry-content-tile p {    
    animation: fadeInText .5s forwards;
    /* animation-delay: 2s; */
}

@keyframes circleToRectangle {
    0% {
        height:0;
        box-shadow: none;
        border-bottom: 1px solid #ccc;
    }
    50% {
        height:auto;
        border-bottom: 1px solid #ccc;
    }
    100% {      
        height: auto;          
        padding:40px 20px;
        box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid #ccc;
    }
}

@keyframes fadeInText {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.close-tile-info{
    position: absolute;
    top:-20px;
    right:20px;
    text-decoration: none;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.05);
    color:#333;
    width:40px;
    height:40px;
    font-size:20px;
    line-height:30px;
    background-color: #fafafa;
    border-radius:50%;
    display:inline-block;
}

.close-tile-info svg{
    transform: rotate(90deg);
    margin-top:7px;
}

.industries-block .slick-next:before,
.industries-block .slick-prev:before{
    color:#93478f;
    font-size:50px;
    display:block;
    background:transparent
}

.industries-block .slick-next:hover:before,
.industries-block .slick-prev:hover:before{
    color:#DF12B2;
}

.industry-content{
    font-size:15px;
    line-height:21px;
}