
.animated-circles-container {
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius:25px;
}

.animated-circles-container .circle {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #762e7f;
    position: absolute;
    bottom:20px;
    opacity: 0;
    animation: circleFade 1s infinite;
}

.animated-circles-container .circle1 {
    left: 30px;
    animation-delay: 0s;
}

.animated-circles-container .circle2 {
    left: 45px;
    animation-delay: 0.3s;
}

.animated-circles-container .circle3 {
    left: 60px;
    animation-delay: 0.6s;
}

@keyframes circleFade {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}
