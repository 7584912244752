.sub-head{
    font-weight: 800;
    font-size: 17px;
    letter-spacing: .3rem;
    color:#741E7E;
    margin-bottom:15px !important;
}

.main-head{
    font-size: 41px;
    line-height: 45px;
    font-weight: 700;
    color:#222A38;    
    margin-top: 0;
    margin-bottom:20px;
}

@media screen and (max-width:767px){
    .tile-container .process-tile {
        padding:0;
    }
    .tile-container .process-tile .card{
        width:100%;
        top:auto;
        position: inherit;
        box-shadow: 0 2px 5px rgba(0,0,0,.15);
    }
    .process-tile .card .card-title{
        font-size:33px;
    }
    .tile-container .process-tile .card:hover{
        transform: none;
    }
    .show-app{  
        max-width:100%;
    } 
    .main-head{
        font-size:35px;
        line-height: 45px;
    }
}