.cont{
    padding:50px 40px;
    border-radius: 25px;
    font-size:25px;
    line-height: 27px;
    position: relative;
    top:-70px;
}

.text-quote{
    padding:0 50px;
    position: relative;
}

.text-quote:before{
    content:'“';
    display: block;
    position: absolute;
    color:#fff;
    font-size: 84px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif; 
    top:0;   
    left:0px;
}

.text-quote:after{
    content:'”';
    display: block;
    position: absolute;
    color:#fff;
    font-size: 84px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;    
    right: 0;
    top:0;
}

.cont p{
    margin:0;
}