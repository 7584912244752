.service-links{
    margin:0;
    padding:0; 
    list-style-type: none;
    margin-top:50px;
    margin-left:50px;
}

.service-links li a,
.service-links li a:visited{
    position: relative;
    font-size: 13px;  
    padding:10px 10px 10px 0;
    list-style: none;
    display: block;  
    text-decoration: none;
    color:#222A38;
}

.service-links li a:before{
    content: "\2014";
    display: inline-block;
    color:#741E7E;
    margin-right:10px;
}

.service-links li a:hover,
.service-links li a:active,
.service-links li a.active{
    color:#741E7E;
    font-weight: bold;
}

@media screen and (max-width:767px){
    .service-hero .service-links {
        padding: 0 !important;
        margin: 0 0 40px 0 !important;
        background: none !important;
    }
    .service-hero .service-links li a{
        font-size:18px;
    }
}