.thank-you-message{
    display:none;
    padding:30px 0;
}

.formLoader{
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    /* background:rgba(255,255,255,.9); */
    
}
@media screen and (max-width:767px){
    .contact-form .card{
        padding:15px 15px;
    }
    .contact-form{
        padding:0;
    }
    .contact-form h2{
        font-size:30px;
    }
}