.ctaCont{
    padding:15px 0;
    text-align: center;
    vertical-align: middle;
    padding:60px 0;
}

.ctaCont span{
    font-size:23px; 
    display: inline-block;
    margin-right:25px;
    vertical-align: middle;
}