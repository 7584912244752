.trusted-customers {
    padding:100px 0 100px 0;
    overflow: hidden;
    position: relative;
    text-align:center;
}
 
.slick-dots li button:before{
    font-size:15px;
    color:#752e7f;
}

.slick-dots li.slick-active button:before{
    color:#752e7f;
}

.trusted-customers::before {
    content: ' ';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background: radial-gradient(ellipse at center, rgba(212, 89, 250, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
}

@media screen and (max-width:767px) {
    .customer-tile{
        border:none !important;
    }
}