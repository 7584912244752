.txt-quote{
    font-size:33px;
    font-weight: bold;
    line-height: 35px;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    margin-left:200px;  
    margin-top: 100px  
}

.txt-quote span{
    font-size:245px;
    position: absolute;
    left:-130px;
    top:30px;
    opacity:.1;
}

@media screen and (max-width:767px){
    .txt-quote{
        margin-left:50px;
    }
    .txt-quote span{
        font-size:100px;
        left:-50px;
        top: -10px;
    }
    .ceo-content{
        padding:0 !important;
    }
    section.ceo-section {
        padding-bottom: 60px;
    }
}