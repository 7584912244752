h1{
    font-weight: bold;
    color:#741E7E;
}

h2{
    font-size:40px;
}

.hero-section{
    padding-top:120px;
}

.image-section img{
    box-shadow:0 10px 30px rgba(0,0,0,.15);
    border-radius:50px;    
}

.contact-room{    
    z-index: 1;
    top:-50px;
    float:right;
    margin-right:40px;
    position:relative;
}

.contact-form{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(100, 0, 0, .02));
}

.contact-space{
}

.contact-form label{
    font-size:15px; 
    color:#999;
    margin-top:10px;
}

.contact-form form .text-danger{
    font-size:13px;
}

.contact-address{
    margin-top:30px;
    padding:0 30px;    
}

.addresses-info{
    background:#741E7E05;
    border-radius:10px;
    padding:20px 30px 30px 30px;
    margin-bottom:30px;
    font-size:15px;
}

.addresses-info a{
    color:#000;
}

.addresses-info a:hover{
    color:#741E7E;
    text-decoration: none;
}

.addresses-info ul{
    margin:0;
    padding:0;
    list-style: none;
}

.addresses-info ul li{
    padding:10px 0 0 0;
    margin:0;
}

.hide-overflow{
    overflow: hidden;
}