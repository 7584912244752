.homeBanner-section{
    padding-top:70px;
    background: url(./images/dna-bg.png) no-repeat bottom right;
    padding-bottom:100px;
    background-color: #fff;
}
@media screen and (max-width:1199px){
    .homeBanner-section {
        background-position-x: 130%;
    }
}

@media screen and (max-width:991px){
    .homeBanner-section {
        background-position-x: 500px;
    }
    .homeBanner-section  .sub-head{
        margin-top:60px !important;
    }
}

@media screen and (max-width:850px){
    .homeBanner-section {
        background-position-x: 450px;
    }
}