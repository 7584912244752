.customer-tile{
    border-radius: 0;
    padding:50px 20px;
    margin:1px;
    background: none;
    border-right:1px solid #79009410; 
    box-shadow: none;
    text-align: center;
}

.customer-image{    
    min-width: unset;
    border:none;
    margin:0;
    padding:0 0 30px; 
    display: inline-block !important;
}

.customer-tile .card-title.h5{
    color:#222A38;
    margin-bottom: 0;
    font-weight: bold !important;
}

.customer-tile .sub-title{
    color:#741E7E;
    margin-bottom:20px;
    display:inline-block;
    font-size: 15px;;
}