.heroBanner{
    padding-top:120px;
    padding-bottom:20px;
    margin-bottom:20px;
    background: linear-gradient(to top, #fbeffe, #ffffff);
}

.policyContainer{
    padding-bottom:60px;
}

.policyContainer h5{
    font-size:17px;
    margin-top:20px;
    color:#741E7E;
}
