
.circleSection{
    position:relative;
    padding:50px 0;
}

.sectionTile .cont
{
    width: 670px;
    height: 670px;
    border-radius: 100%;
    position: relative;
    top: -150px;
    left: -7em;
    z-index: -1;
} 

.sectionTile .imgCont{
    width:470px;
    height:470px;
    overflow: hidden;
    border-radius: 100%;
    position: absolute;
    right:40px;
    bottom: 40px;
}

.sectionTile .imgCont img{
    max-width: 100%;
    max-height: 100%;
}

.sectionTile .author-msg{
    position: absolute;
    right:-320px; 
    bottom:30px;
}

.author-msg p{
    background:#fff;
    padding:20px;
    border-radius: 25px;    
    width:420px;
    max-width:100%;
    font-size:19px;
    box-shadow: 0 10px 30px rgba(0,0,0,.16) ;
    border-top-left-radius: 0;
    position: relative;
    z-index: 100;
}

.order-md-2 .sectionTile .cont{
    right:-80px;
    left:auto;
    z-index: -1;
}

.order-md-2 .sectionTile .imgCont{
    left:40px;
}

.order-md-2 .sectionTile .author-msg p{
    border-top-right-radius: 0;
}

.order-md-2 .sectionTile .author-msg{
    left:-320px; 
    bottom:30px;
}

.sectionTile .author-msg span {
    font-size: 17px;
    display: block;
    margin-top: 15px;
    margin-left: 15px;
}

.clear-height{
    min-height: auto !important;
}

@media screen and (max-width:1199px){
    .sectionTile .author-msg{
        bottom: -50px;
    }
}

@media screen and (max-width:991px){
    .sectionTile .cont{
        left: -10em;
    }
}

@media screen and (max-width:800px){
    .sectionTile .author-msg{
        right:-120px;
    }
    .sectionTile .cont{
        left: -12em;
    }
    .sectionTile .cont{
        top:-50px
    }
    .order-md-2 .sectionTile .author-msg,
    .sectionTile .author-msg{
        bottom:-30px;
    
    }
    .order-md-2 .sectionTile .cont
    {
        right:40px;
    }
}

@media screen and (max-width:767px){
    .sectionTile .cont{
        left: -10em;
        top: -120px;
    }
    .sectionTile .author-msg{
        left:50%;
        max-width: 90vw;
        margin-left:-20vw;
    }
}