.bannerCircles {
    position: absolute;
    z-index: -1;
    min-height: 200vh;
    width: 100%;
    overflow: hidden;
}

.bannerCircles div {
    width: 800px;
    height: 800px;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    opacity: .4;
}

.bannerCircles div:nth-child(1) {
    left: -400px;
    top: -400px;
    opacity: 1;
    position: relative;
}

.bannerCircles div:nth-child(2) {
    left: 100px;
    top: -138px;
    background: #F500FF;
    opacity: .05;
}

.bannerCircles div:nth-child(3) {
    left: auto;
    right: -200px;
    top: -300px;
    background-color: #fff;
    width: 900px;
    height: 900px;
    opacity: .4;
}

.bannerCircles div:nth-child(4) {
    left: -300px;
    top: 50px;
    background-color: #fff;
    width: 900px;
    height: 900px;
}

.bannerCircles div:nth-child(5) {
    right: -100px;
    top: 150px;
    background-color: #DBFFEE;
    width: 1000px;
    height: 1000px;
    opacity: .08;
}

.clientName {
    font-weight: 600;
    font-size: 55px;
}

.clientNameInitial {
    font-size: 100px;
    font-weight: 600;
    letter-spacing: -10px;
    line-height: 80px;
}

.caseStudyHead {
    padding-top: 400px;
    min-height: 100vh;
}

.slideShow {
    min-height: 100vh;
    position: relative;
}

.slideShow>div {
    background: #fff;
    width: 600px;
    height: 600px;
    position: absolute;
    border-radius: 50%;
    opacity: .5;
}

.slideShow>div:nth-child(1) {
    left: 0;
    top: 100px;
}

.slideShow>div:nth-child(2) {
    left: 50%;
    margin-left: -300px;
    opacity: 1;
    overflow: hidden;
    background: #fefeff;
    border: 10px solid #fff;
    z-index: 10;
    box-shadow: 0 20px 30px rgba(0, 0, 0, .03);
}

.slideShow div:nth-child(3) {
    right: 0;
    top: 100px;
}

.slideScreen {
    height: 380px;
}

.ContentTile {
    padding: 0 100px;
}

.textContent {
    background: #fff;
    padding: 30px 100px;
    font-size: 14px;
}

.textTitle {
    text-align: right;
    font-weight: bold;
    font-size: 18px;
}

.case-study-analytics {
    padding: 60px;
    min-height: 100vh;
}

.case-study-analytics .sub-title {
    font-weight: 800;
    font-size: 17px;
    letter-spacing: .3rem;
    color: #741E7E;
    margin-bottom: 15px !important;
    text-transform: uppercase;
}

.case-study-analytics .sec-title {
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    color: #222A38;
    margin-top: 0;
    margin-bottom: 20px;
}

.case-study-analytics img{
    mix-blend-mode: multiply;
    /* filter:hue-rotate(100deg); */
    opacity: .7;
}

.slide-dots{
    position: absolute;
    width:100%;
    text-align: center;
    bottom:20px;
    z-index: 100;
}

.slide-dots span{
    display: inline-block;
    width:13px;
    height:13px;
    background:#E3AEE3;
    border-radius:30px;
    margin:3px;
}

.slide-dots span.active{
    background:#EB0FE2;
}

.screen-shots{
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}

.screen-props{
    height:400px;
    border-radius:300px;
    min-width:700px;
    max-width:80%;
    background:rgba(255,255,255,.4);  
    display: inline-block;  
    position: absolute;
    z-index: -1;
}

.screen-shots .screen-props:nth-child(2){
    left:-250px;
    bottom:0;
}

.screen-shots .screen-props:nth-child(1){
    right:-250px;
    top:0;
}

.screen-shot{
    margin-top: 250px;
    text-align: center;
    border-radius: 250px;
    height: 550px;
    overflow: hidden;
}

.sub-analytical{
    min-height: auto;
    padding-bottom:0;
}

.conclusion{
    padding:80px 0;
}

.company-statement{
    background-color: #fff;
    padding:60px 0;
}

.company-statement h5{
    margin-bottom:20px;
}

.company-statement a{
    color:#3a3a3a
}

@media screen and (max-width:767px){
    .screen-shots,.slideShow{
        min-height: auto;
        
    }

    .slideShow{
        overflow: hidden;
        padding-top:30px;
        padding-bottom:80px;
    }

    .slideShow>div{
        position:inherit;
    }

    .slideShow > div:nth-child(3), 
    .slideShow > div:nth-child(1)
    {
        display: none;
    }
    .case-study-analytics{
        padding:30px
    }
    .conclusion{
        padding:30px;
    }
}