
.circleSection{
    position:relative;
    padding:50px 0;
}

.imageSectionTile .cont
{
    width: 670px;
    height: 670px;
    border-radius: 100%;
    position: relative;
    top: -30px;
    left: -7em;
} 

.imageSectionTile .imgCont{
    width:470px;
    height:470px;
    overflow: hidden;
    border-radius: 100%;
    position: absolute;
    right:40px;
    bottom: 40px;
}

.imageSectionTile .imgCont img{
    max-width: 100%;
    max-height: 100%;
}

.imageSectionTile .author-msg{
    position: absolute;
    right:-320px; 
    bottom:30px;
}

.imageSectionTile .author-msg p{
    background:#fff;
    padding:20px;
    border-radius: 25px;    
    width:420px;
    font-size:19px;
    box-shadow: 0 10px 30px rgba(0,0,0,.16) ;
    border-top-left-radius: 0;
}

.order-md-2 .imageSectionTile .cont{
    right:-80px;
    left:auto;
    z-index: -1;
}

.order-md-2 .imageSectionTile .imgCont{
    left:40px;
}

.order-md-2 .imageSectionTile .author-msg p{
    border-top-right-radius: 0;
}

.order-md-2 .imageSectionTile .author-msg{
    left:-320px; 
    bottom:30px;
}

.imageSectionTile .author-msg span {
    font-size: 17px;
    display: block;
    margin-top: 15px;
    margin-left: 15px;
}

.clear-height{
    min-height: auto !important;
}