.navbar .navbar-nav .nav-link,
.navbar .navbar-nav .nav-link:active{
    color:#741E7E;
    font-weight: 700;
    padding:10px;
    position: relative;
}

.navbar .navbar-nav .nav-link:hover{
    color:#DF12B2;
}

.navbar .navbar-nav .nav-link.active:after,
.navbar .navbar-nav > div.nav-item.active:after{
    content:"";
    display:block;
    border:3px solid #741E7E;
    border-radius:20px;
    position: absolute;
    bottom: 0;
    width:50%;
    left:25%;
} 

.navbar .navbar-nav .nav-link svg{
    vertical-align: top;
}

.navbar .navbar-nav .nav-link svg #border_svg,
.navbar .navbar-nav .nav-link svg #Rectangle_11,
.navbar .navbar-nav .nav-link svg #Rectangle_12,
.navbar .navbar-nav .nav-link svg #svg_pencil
{
    fill:#741E7E;
}

.navbar .navbar-nav .nav-link:hover svg #border_svg,
.navbar .navbar-nav .nav-link:hover svg #Rectangle_11,
.navbar .navbar-nav .nav-link:hover svg #Rectangle_12,
.navbar .navbar-nav .nav-link:hover svg #svg_pencil
{
    fill:#DF12B2;
}

.navbar-toggle-mobile,
.navbar-brand-mobile{
    display:none;
}

.navbar-toggle-mobile{
    width:fit-content !important;
}

.btn-get-started{
    background:#741E7E;  
    color:#fff !important;
    border-radius:100px;
    padding-left: 30px !important; 
    padding-right: 30px !important; 
}

.btn-get-started:hover{
    background:#DF12B2;  
    color:#fff !important; 
}

.btn-get-started.active:after{
    display:none !important;
}

button.navbar-toggler:focus,
button.navbar-toggler:active{
    outline:none;
    border:none;
    box-shadow: none;
}

button.navbar-toggler {
    border: none;
    margin-right: 11px;
}

@media screen and (max-width:991px){
    .navbar-toggle-mobile,
    .navbar-brand-mobile{
        display:block;
    }
}

@media screen and (max-width:767px){    
    .navbar-collapse{
        border-radius: 20px;
        padding:20px;
    }
    .navbar .navbar-nav .nav-link::after{
        display: none !important;
    } 
    .navbar .navbar-nav .nav-link:last-child{
        border:none;
    }
}
