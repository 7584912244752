section.main-section.serviceSection {
    min-height: 100vh;
    padding-top:100px;
    overflow: hidden;
}

.serviceCircles{        
    width:1000px;
    height:1000px;
    position: absolute;
    left:-200px;
    top:-200px;    
    padding:100px; 
    border-radius: 100%;
}

.serviceCircles .circle{
    padding:100px; 
    border-radius: 100%;
    width:100%;
    height:100%;
}

.serviceCircles .circle .circle-content{
    position: absolute;    
}

.why-choose-aws li, 
.benefits-service li{
    background:none;
    border:none;
    padding:20px 30px;
    border:none;
    margin-bottom:10px;
    cursor:pointer;
}

.why-choose-aws li p,
.benefits-service li p{
    margin:0;
}


.service-description-section{
    padding:20px 0 80px 0;
}
.technology-section{
    background:#fefefe;
    padding:20px 0 80px 0;
}

.technology-section .tech-stack{
    padding:20px 0;
}
 

.service-icon-image,
.number-icon-image{
    opacity: .1;
    width:80px;
    height:80px;
}

.why-choose-aws li:hover .service-icon-image,
.benefits-service li:hover .number-icon-image{
    opacity:.6;
}

.serviceSection .footNotes{
    margin:80px 0 0 0;
    padding:0;
    list-style: none;
    position: absolute;
    width: 100%; 
}

.serviceSection .footNotes li{
    display: inline-block;
    padding-right:30px;
    font-size: 15px;
    font-weight: bold;
}

.serviceCircles h1, h2, h3, h4{
    margin:0;
    font-weight: normal;
}

.serviceCircles h4{
    text-transform: uppercase;
    font-size: 20px;
}

.serviceCircles h1{
    margin-bottom:20px;
}

.sub-service{
     
}

.sub-service-detail{
    background:#fff;
    padding:20px;
    border-radius:20px;
    box-shadow: 0 0 15px rgba(0,0,0,.05);
    position: absolute;
    width: 350px;
    height:auto;
    z-index: 1;
    font-weight:400;
    font-size:15px;
    line-height:19px;
    display: none;
    color:#333 !important;
}

.sub-service-image img{
    mix-blend-mode: multiply;
    /* filter: hue-rotate(100deg); */
    opacity: .7;
    max-width:500px;
}

.icon-list{
    padding-left: 0;
    list-style: none;
}
.icon-list li{
    padding:7px 15px;
    font-weight: 600; 
    transition: all .2s ease-in-out;
    cursor:default;
    display: inline-block;
}
.icon-list li:hover
{
    transform: scale(1.1);
    color:#DF12B2;
}

.icon-list li:hover .sub-service-detail{
    display: block;
}

.icon-list li::before {
    display: inline-block;
    flex-shrink: 0;
    width: 1.5em;
    height: 1.5em;
    margin-right: .5rem;
    content: "";
    vertical-align: middle;
    background: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E) no-repeat center center / 100% auto;
}

 

@media screen and (max-width:1140px) {
    .serviceCircles{
        left:-300px;
        top:-240px;
    }   
    .footNotes{
        margin-top:0;
    }
}

@media screen and (max-width:1023px) {
    .circle-content{
        max-width:400px;
    }
}

@media screen and (max-width:800px) {
    .serviceCircles{
        left:-300px;
    }
}

@media screen and (max-width:767px) {
    .circle-content,
    .footNotes{
        position: static !important;
        text-align: center;
        padding:0 15px;
    }
    .icon-list li{
        display: block;
    }
    
    .serviceCircles{
        position: absolute;
        top:-50px;
        left:0;
        margin-left:-500px;
    }

    .circle-content{
        padding-top:100px;
        height: 100vh;
    }
    
    .service-links{
        display: none;
    }
    .circle-content h1{
        font-size:45px;
        line-height:45px;
        margin-top:20px;
        font-weight: 700 !important;
    }
}

