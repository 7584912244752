.build-section{
     padding:0 30px 110px 30px !important;
     overflow: hidden;
     background-color: #ff00f705;
     background-image:url("https://jasthinkassets.s3.amazonaws.com/working-pro.jpg");
     background-repeat: no-repeat;
     background-size:cover;
     background-attachment: fixed;
}

.floor-shadow{
    position: relative;
}

.floor-shadow:before {
    content: ' ';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    background: radial-gradient(ellipse at center, rgba(212, 89, 250, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
}

.sub-head{
    margin:80px 0 0 0;
}

.form-card {
    margin-top:60px;
}
.form-card .dropdown button, .form-card .dropdown-menu, .form-card .btn-primary{
    width:100% !important;
}

.show-app{
    margin:90px 0 0 0;
}

@media screen and (max-width:767px) {
    .sticky-panel{
        padding:60px;
        width:80%;
        margin:0 auto;
    }
    .build-section{
        padding:80px 0;
    }
}

@media screen and (max-width:767px) {
    .sticky-panel{
        padding:30px;
        width:100vw;
        margin:0 auto;
    }
    section.main-section.build-section{
        padding:0 0 160px 0 !important;
    }
}