@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* * {
  transition:all .1s ease-in-out;
} */

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:17px;
  color:#001F40;
  /* background:#fef7ff url(https://jasthinkassets.s3.amazonaws.com/gradient.png) top right no-repeat; */
  background:#fef7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  max-width:100%;
}

.navbar{
  background:transparent;
  transition: all .5s ease-in-out;
}

.navbar.active{
  background:#FFFFFFCC;
  top:-100px;
  /* padding:0px; */
  transition: top 1s;  
}

.navbar.active .navbar-brand img{
  /* height: 20px;
  clip-path: rect(10px, 20px, 30px, 40px); */
}

.navbar.active a.nav-link{
  /* font-size: 11px;
  padding: 5px;
  margin:0 5px; */
}

.navbar.active a.nav-link[role="button"] {
  /* padding: 5px 10px; */
}

.btn-primary,
.btn-primary:visited,
.btn-primary:focus{
  background-color: #741E7E;
  border-color: #741E7E;
  color:#fff !important;
  border-radius: 50px;
  padding:10px 30px;
  font-weight: 700;
  display:inline-block;
  font-size:1.2em;
  margin:15px 0;
}

.btn-primary:hover{
  background-color: #DF12B2;
  color:#fff;
  border-color: #DF12B2;
}

.btn-primary:active{
  background-color: #DF12B2;
  border-color: #DF12B2;
  color:#fff;
}

.hash-tags{
  font-size: 15px;
  color:#AF5BA1;
  font-family: Arial, Helvetica, sans-serif;   
  /* position: absolute;
  bottom: 35px; */
}

.team-member{
  border-radius: 50%;
  float: left;
  margin-right:15px;
  width:80px;
  height:80px;
  margin-top: 15px;
  vertical-align: middle;
  margin-bottom:30px;
}

.team-member ~ span{
  vertical-align: middle;
}

.team-member ~ span::after{
  display: block;
  content: "";
  clear:both;
}

.form-section{
  padding: 60px 0;
}

.strong{
  font-weight: 600 !important;
}

.card{
  border-radius:25px;
  padding:30px;
  border:none;
  box-shadow:0 10px 30px rgba(0,0,0,.15);
}

.form-card .card-title{
  font-size: 35px;
  font-weight: 700;
  margin-bottom:40px;
}

.form-card label{
  font-weight: 600;
}

.form-card .dropdown button{
  padding: 15px;
  border:1px solid #ccc; 
  text-align: left;
}

.form-card .dropdown-toggle::after{
  float: right;
  margin-top:9px;
}

section.main-section{
  position: relative;
  /* min-height:100vh; */
  padding:80px 0;
}

.voilet{
  color:#741E7E;
  font-weight: 700;
  font-size: 19px;
}

.show-on-mobile{
  display: none;
}

.spacer{
  display:block;
  height:50px;
  border:none;
  outline:none;
}

input.form-control::placeholder {
  color: #DDD;
  font-style: italic;
}
 
select option[value=""].empty {
  color: #DDD; /* Color for options with empty values */
}

.sticky-panel {
  padding:5rem;
}

.sticky-cyan{
  background:#a2e7f9;
}

.sticky-panel .sub-head{
  margin-top:0;
}

.highlight-text{
  font-size:1.2em;
}

.spacer{
  height:80px;
}

.parallax-left{
  transition: all .2s ease-in;
  transform: translateX(-150px);
}

.parallax-right{
  transition: all .2s ease-in;
  transform: translateX(150px);
}

.top-shadow{
  /* margin-top:60px !important; */
  position:relative;
  overflow: hidden;
  padding-top:100px !important;
}

.bottom-shadow{
  padding-bottom:100px !important;
  overflow: hidden;
  position: relative;
  /* margin-bottom:60px; */
}

.dropdown-item{
  padding:16px;
  border-radius:10px;
}

.dropdown-item.active, .dropdown-item:active{
  background:#AF5BA1;
  border-radius:10px;
}

.dropdown-menu{
  padding:0;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  border-radius: 20px;
  padding: 10px;
}

.overflow-wrap {
  display: flex;
  align-items: flex-start;
}

.top-shadow::before {
  content: ' ';
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100px;
  background: radial-gradient(ellipse at center, rgba(212, 89, 250, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
}

.bottom-shadow::after {
  content: ' ';
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 100px;
  background: radial-gradient(ellipse at center, rgba(212, 89, 250, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
}

.animated-li p{
  margin:0; 
}

.animated-li li:hover  {
  background: #741E7E;  /* Initial fill color */
  border-radius:20px;
  transition: transform .2s ease-in-out;
  animation: gradient-loop infinite alternate 2s ease-in-out;
}

@keyframes gradient-loop {
  0% { background: #741E7E33; }  /* Start with initial color */
  25% { background: #DF12B233; }   /* Transition to cyan */
  50% { background: #12b6df33; } /* Transition to magenta */
  75% { background: #dcdf1233; }  /* Transition to yellow */
  100% { background: #1515d233; } /* Back to initial color */
}

@media screen and (max-width:991px){
  .container{
    width:97.5% !important;
    max-width:none;
  }
  .navbar-nav{
    background:#FFFFFF !important;
    top:0;
    right:0;
    height: 100vh;
    width:95%;
    text-align: right;
    position: fixed;
    z-index: 100;
    padding:20px 20px 90px 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    overflow:hidden;
    overflow-y:auto;
    transform: translateX(100%);
    transition: transform 0.2s ease-out, opacity 0.5s ease-out;
    opacity: 0;
    border-left:#741E7E 7px solid;
    box-shadow: -15px 0 10px rgba(0, 0, 0, .05);
  }

  .navbar .navbar-nav .nav-link, .navbar .navbar-nav .nav-link:active{
    font-size:50px;
    line-height: 70px;
  }

  .navbar .btn-get-started{
    background:none !important;
    border-radius:0 !important;
    color:#741E7E !important;
    padding:0 !important;
    border-top:1px solid #741E7E !important;
    margin-top:15px;
    padding-top:20px !important;
  }

  #navbarScroll.show .navbar-nav{
    transform: translateX(0);
    opacity: 1;
  }
  .navbar-nav .dropdown-menu{
    padding:0;
    box-shadow: none;
    font-size:20px;
    line-height: 20px;
    text-align: right;
  }
  .navbar .navbar-nav .nav-link.active:after, .navbar .navbar-nav > div.nav-item.active:after{
    display:none !important;
  }
}

@media screen and (min-height: 800px) and (orientation: portrait) {
  section.main-section {
    min-height: auto !important;
    padding-bottom: 60px !important;
  }
}

@media screen and (max-width:767px){
  .show-on-mobile{
    display:block;
  }
  .show-on-desktop{
    display:none;
  }
  .sticky-cyan{
    padding:0 15px 40px 15px;
  }
  .navbar .navbar-nav .nav-link, .navbar .navbar-nav .nav-link:active{
    font-size:30px;
    line-height:50px;
  }
}